.table {
    &--primary {
        position: relative;
        z-index: 3;
        margin-bottom: 8px;

        th, td {
            
            font-size: 12px;
            padding: 11px;
            border-top: none;
            white-space: nowrap;
            background-color: $white;
            //@include transition(background-color 100ms ease-in);

            @media print {
                white-space: inherit;
            }

            i {
                font-size: 1rem;
                line-height: normal;
            }

            &.col-wrap {
                white-space: normal;
                width: 100%;
            }

            &.stick {
                &-end {
                    position: sticky !important;
                    right: 0 !important;
                    z-index: 2;
                }
            }
        }

        th {
            border-left: 1px solid #4da7b0;

            &:first-child {
                border-left: none;
            }

            &.stick {
                &-end {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 8px;
                        left: -50px;
                        width: 50px;
                        background: rgb(39, 201, 118);
                        background: linear-gradient(100deg, rgba(28,87,156,0) 0%, var(--primary) 100%);
                    }

                    &.stuck {
                        &:before {
                            width: 0;
                            background: rgba(28,87,156,0);
                        }
                    }
                }
            }
        }

        td {
            white-space: normal !important;
            text-align: justify;
                    
            border-left: 1px solid #D3D5D7;

            &:first-child:not(.no-radius) {
                border-left: none;
            }

            &.stick {
                &-end {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 8px;
                        left: -50px;
                        width: 50px;
                        background: rgb(255,255,255);
                        background: linear-gradient(100deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                    }

                    &.stuck {
                        &:before {
                            width: 0;
                            background: rgba(255,255,255,0);
                        }
                    }
                }
            }

            &.bottom,
            &.left-corner,
            &.right-corner {
                position: relative;
                padding-bottom: 19px;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: -1px;
                    right: -1px;
                    height: 8px;
                    background-color: $green;
                    z-index: 1;
                }
            }

            &.left-corner {
                &:after {
                    left: 0;
                    border-bottom-left-radius: 4px;
                }
            }
            &.right-corner {
                &:after {
                    right: 0;
                    border-bottom-right-radius: 4px;
                }
            }
        }

        tbody {

            tr {
                &.disabled {
                    color: rgba($black, 0.5);

                    .text-primary {
                        color: rgba(var(--primary), 0.5) !important;
                    }
                }
                &:nth-child(even) {
                    th, td {
                        background-color: #F9F9F9;
                    }

                    td {
                        &.stick {
                            &-end {
                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    bottom: 8px;
                                    left: -50px;
                                    width: 50px;
                                    background: #F9F9F9;
                                    background: linear-gradient(100deg, rgba(#F9F9F9,0) 0%, rgba(#F9F9F9,1) 100%);
                                }
            
                                &.stuck {
                                    &:before {
                                        width: 0;
                                        background: rgba(#F9F9F9,0);
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    th, td {
                        background-color: #F2F5F9;
                    }
                    td {
                        &.stick {
                            &-end {
                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    bottom: 8px;
                                    left: -50px;
                                    width: 50px;
                                    background: #F2F5F9;
                                    background: linear-gradient(100deg, rgba(#F2F5F9,0) 0%, rgba(#F2F5F9,1) 100%);
                                }
            
                                &.stuck {
                                    &:before {
                                        width: 0;
                                        background: rgba(#F2F5F9,0);
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(even) {
                        th, td {
                            background-color: #EDF2F8;
                        }
                    }
                }

                &:last-child {
                    td {
                        position: relative;
                        padding-bottom: 19px;

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: -1px;
                            right: -1px;
                            height: 8px;
                            background-color: var(--primary);
                            z-index: 1;
                        }

                        &:first-child:not(.no-radius) {
                            &:after {
                                left: 0;
                                border-bottom-left-radius: 4px;
                            }
                        }
                        &:last-child:not(.no-radius) {
                            &:after {
                                right: 0;
                                border-bottom-right-radius: 4px;
                            }
                        }
                    }
                }

                &.highlight {
                    td {
                        position: relative;
                        z-index: 1;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            background-color: var(--primary);
                            z-index: -1;
                            animation: fadeOut 2s linear forwards;
                        }
                    }
                }
            }
        }

        thead {

            th, td {
                color: white;
                background-color: var(--primary);
                font-weight: 600;
                text-align: center;
                line-height: 40px;
                padding: 0 11px;
                border-bottom: none;

                a {
                    color: inherit;
                    display: block;
                    padding: 0 11px;
                    margin: 0 -11px;
                    //@include transition(100ms all ease);

                    &:hover {
                        background-color: rgba(0,0,0,.1);
                    }
                }

                &:first-child:not(.no-radius) {
                    border-top-left-radius: 4px;
                }
                &:last-child:not(.no-radius) {
                    border-top-right-radius: 4px;
                }
            }
        }
    }

    &__tools {
        border-bottom: 1px solid #D9D9D9;
        margin-bottom: 36px;
        padding-bottom: 8px;
    }
    &--package {
        th, td {
            padding: 11px 0;
            vertical-align: initial;
            width: calc(100% / 12);
        }

        th {
            font-size: 12px;
            padding: 18px 0;
        }

        td {
            color: var(--primary);
            text-align: right;
            font-size: 24px;
            font-weight: 600;

            sub {
                font-size: 12px;
                top: 0;
                font-weight: bold;
            }
        }
    }

    &--middle {
        th, td {
            vertical-align: middle;
        }
    }

    @media print {
        border-collapse: separate;
        thead {
            th, td {
                border: 1px solid $gray-100 !important;
                padding: 8px !important;
                color: var(--primary);
                line-height: normal !important;
            }
        }
        tbody {
            th, td {
                border: 1px solid $gray-100 !important;
                padding: 8px !important;
                line-height: normal !important;

                &:after {
                    content: none !important;
                }
            }
        }
    }
}

@keyframes fadeOut {
    0% {opacity: 0;}
    20% {opacity: .3;}
    100% {opacity: 0;}
  }