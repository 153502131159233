.modal {
    &__wrapper {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($primary, .8);
        text-align: center;
        overflow-y: auto;
        padding: 20px;

        &:before {
            content: '';
            display: inline-block;
            font-size: 0;
            height: 100%;
            vertical-align: middle;
            width: 0;
        }
    }

    &__container {
        position: relative;
        background-color: $white;
        border-radius: 4px;
        padding: 16px;
        width: 100%;
        max-width: 812px;
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }

    &__header {
        padding-bottom: 16px;
        border-bottom: 1px solid #D9D9D9;

        h3 {
            font-size: 24px;
            letter-spacing: -0,31px;
            font-weight: normal;
            color: $primary;
            line-height: 28px;
            margin: 0;
        }
    }
    &__close {
        position: absolute;
        top: 18px;
        right: 16px;
        font-size: 24px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        display: inline-block;
        z-index: 10;
    }
    &__footer {
        border-top: 1px solid #D9D9D9;
        padding-top: 16px;
    }

    // Bootstrap modal

    &-title {
        font-size: 24px;
        letter-spacing: -0,31px;
        font-weight: normal;
        color: $primary;
        line-height: 28px;
        margin: 0;
    }

    &-content {
        position: relative;
        background-color: $white;
        border-radius: 4px;
        padding: 16px;
        border: none;
    }

    &-header {
        position: relative;
        padding: 0;
        padding-bottom: 16px;
        border-bottom: 1px solid #D9D9D9;
        border-radius: 0;

        .close {
            position: absolute;
            top: 18px;
            padding: 0;
            right: 16px;
            font-size: 0;
            width: 24px;
            height: 24px;
            line-height: 24px;
            display: inline-block;
            z-index: 10;

            @extend %fa-icon;
            @extend .fas;

            &:before {
                content: fa-content($fa-var-times);
                font-size: 24px;
            }
        }
    }

    &-body {
        padding: 16px 0;
    }

    &-footer {
        padding: 0;
        padding-top: 16px;
        border-radius: 0;
        justify-content: flex-start;
    }

    &-backdrop {
        background-color: $primary;
        opacity: .8;
    }
}