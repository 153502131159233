.omni {
    &__search {
        padding: 0;
        margin: 0;
    }
    &__result {
        list-style: none;
        padding: 0;
        margin: 0;
        line-height: 40px;
        color: #6d7278;
        // max-height: 340px;
        overflow: auto;

        li {
            border-bottom: 1px solid rgba($primary, 0.1);

            &:last-child {
                border-bottom: none;
            }

            a {
                padding: 0 12px;
                color: initial;
                display: block;
                border-radius: 4px;
                color: #6d7278;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                p {
                    line-height: normal;
                    padding-bottom: 8px;
                    margin-top: -8px;
                    color: rgba($text-muted, 0.5);
                    margin-bottom: 0;
                }

                &:hover,
                &.hovered {
                    background-color: rgba($primary, 0.04);
                    color: $primary;

                    p {
                        color: rgba($text-muted, 0.5);
                    }
                }

                &.selected {
                    position: relative;
                    background-color: $primary;
                    color: $white;
                    padding-right: 28px;

                    p {
                        color: rgba($white, 0.6);
                    }

                    &:hover {
                        p {
                            color: rgba($text-muted, 0.5);
                        }
                    }

                    &:after {
                        @include material-icon("\e86c");
                        font-size: 20px;
                        position: absolute;
                        right: 6px;
                        bottom: 50%;
                        transform: translateY(50%);
                    }
                }
            }
        }
    }
    &__detail {
        min-height: 400px;
    }

    &__selecteds {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: -12px;
        margin-bottom: 12px;
        font-size: 10px;

        li {
            position: relative;
            display: inline-block;
            background-color: $primary;
            color: $white;
            padding: 2px 4px;
            border-radius: 2px;
            padding-right: 21px;
            margin-right: 2px;
            margin-bottom: 4px;

            a {
                color: $red;
                position: absolute;
                top: 0;
                right: 0;
                font-size: 12px;
                display: inline-block;
                width: 20px;
                text-align: center;

                &:hover {
                    color: lighten($red, 10%);
                }
            }
        }
    }

    &--floating {
        position: relative;
        .omni {
            &__result {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background-color: white;
                z-index: 10;
                background-color: #fafbfd;
                max-height: 300px;

                li {
                    a {
                        border-radius: 0;
                    }
                }

                &.up {
                    top: auto;
                    bottom: 100%;
                }

                &.none {
                    opacity: 0;
                    visibility: hidden;
                }

                &--selected {
                    position: relative;
                    top: auto;
                    margin-top: -16px;
                    line-height: 32px;
                    background: none;

                    li {
                        margin-bottom: 16px;

                        a {
                            border-radius: 4px;

                            &.selected {
                                &:hover {
                                    background-color: rgba($primary, 0.04);
                                    color: $primary;
                                    &:after {
                                        content: "\e5c9";
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
