// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~admin-lte/dist/css/adminlte.css';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

// sweet alert
@import 'sweetalert2/src/sweetalert2.scss';
// import 'sweetalert2/src/sweetalert2.scss'
@import 'mixins';
@import 'table';
@import 'uploader';
@import '~select2/dist/css/select2.css';
@import 'select2';
@import 'omni';
@import 'modal';
@import 'notification';
@import 'style';

.blue {color: $blue;}
.indigo {color: $indigo;}
.purple {color: $purple;}
.pink {color: $pink;}
.red {color: $red;}
.orange {color: $orange;}
.yellow {color: $yellow;}
.green {color: $green;}
.teal {color: $teal;}
.cyan {color: $cyan;}


.navbar[data-theme="sangkuriang"] {
  background-color: $orange;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}
.navbar[data-theme="menara"] {
  background-color: $dark-blue;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}
.navbar[data-theme="pahlawan"] {
  background-color: $dark-blue;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}
.navbar[data-theme="nusarasa"] {
  background-color: $green;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.main-footer[data-theme="sangkuriang"]{  background-color:  $orange}
.main-footer[data-theme="menara"]{  background-color:  $dark-blue}
.main-footer[data-theme="pahlawan"]{  background-color:  $purple}
.main-footer[data-theme="nusarasa"]{  background-color:  $green}

.content-wrapper[data-theme="sangkuriang"]{  background-color:  $body-bg}
.content-wrapper[data-theme="menara"]{  background-color:  $body-bg}
.content-wrapper[data-theme="pahlawan"]{  background-color:  $body-bg}
.content-wrapper[data-theme="nusarasa"]{  background-color:  $body-bg}

.content-header[data-theme="sangkuriang"]{  background-color:  $green}
.content-header[data-theme="menara"]{  background-color:  $green}
.content-header[data-theme="pahlawan"]{  background-color:  $green}
.content-header[data-theme="nusarasa"]{  background-color:  $green}

.main-sidebar[data-theme="sangkuriang"] {  background-color: $dark-blue}
.main-sidebar[data-theme="menara"] {  background-color:  $blue}
.main-sidebar[data-theme="pahlawan"] {  background-color: $cyan}
.main-sidebar[data-theme="nusarasa"] {  background-color: $orange}

.sidebar[data-theme="sangkuriang"] { background-color:  $dark-blue}
.sidebar[data-theme="menara"] { background-color:  $blue}
.sidebar[data-theme="pahlawan"] { background-color:  $cyan}
.sidebar[data-theme="nusarasa"] { background-color:  $orange}


.user-panel[data-theme="sangkuriang"] { 
  background: linear-gradient(180deg, $orange,$dark-blue);
  border-width: 5px 0px 0px 0px;
  margin-left : -10px;
  margin-right: -10px;  
  margin-top: 0px !important;
}
.user-panel[data-theme="menara"] { 
  background: linear-gradient(180deg, $dark-blue,$blue);
  border-width: 5px 0px 0px 0px;
  margin-left : -10px;
  margin-right: -10px;  
  margin-top: 0px !important;
}
.user-panel[data-theme="pahlawan"] { 
  background: linear-gradient(180deg, $dark-blue, $cyan);
  border-width: 5px 0px 0px 0px;
  margin-left : -10px;
  margin-right: -10px;  
  margin-top: 0px !important;
}
.user-panel[data-theme="nusarasa"] { 
  background: linear-gradient(180deg, $dark-blue,$orange);
  border-width: 5px 0px 0px 0px;
  margin-left : -10px;
  margin-right: -10px;  
  margin-top: 0px !important;
}


// .content-header{
//   padding: 10px 0.5rem !important;
// }

// Variables

.y-video__media {
  position:absolute !important;
  top : 60px;
  left : 100px;
  width: 225px !important;
  height: 200px !important;
}
//.y-video__inner{
//  width: 350px !important;
//  height: 200px !important;
//  padding-bottom: 0 !important;
//}
.pin {
  background-color: #aaa;
  display: block;
  height: 32px;
  width: 2px;
  position: absolute;
  left: 92%;
  top: 0px;
  z-index: 1;
  transform: rotate(120deg);
}

.pin:after {
  background-color: #A31;
  background-image: radial-gradient(25% 25% at 0 0, circle, hsla(0,0%,100%,.3), hsla(0,0%,0%,.3));
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px hsla(0,0%,0%,.1), inset 3px 3px 3px hsla(0,0%,100%,.2), inset -3px -3px 3px hsla(0,0%,0%,.2), 23px 20px 3px hsla(0,0%,0%,.15);
  content: '';
  height: 12px;
  left: -5px;
  position: absolute;
  top: -10px;
  width: 12px;
}

.pin:before {
  background-color: hsla(0,0%,0%,0.1);
  box-shadow: 0 0 .25em hsla(0,0%,0%,.1);
  content: ''; 

  height: 24px;
  width: 2px;
  left: 0;
  position: absolute;
  top: 8px;
 
  transform: rotate(57.5deg);
  -moz-transform: rotate(57.5deg);
  -webkit-transform: rotate(57.5deg);
  -o-transform: rotate(57.5deg);
  -ms-transform: rotate(57.5deg);
 
  transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
}
