
[data-theme="sangkuriang"]{
  --primary : #38c172;
  --secondary : #174073;
  .sidebar a {
    color: $orange !important;
  }
}
[data-theme="nusarasa"]{
  --primary : #f6993f;
  --secondary : #d9d9d9;
  .sidebar a {
    color: $white!important;
  }
}
[data-theme="pahlawan"]{
  --primary : #6574cd;
  --secondary : #38c172;
  .sidebar a {
    color: $white !important;
  }
}
[data-theme="menara"]{
  --primary : #6cb2eb;
  --secondary : #d9d9d9;
  .sidebar a {
    color: $white !important;
  }
}