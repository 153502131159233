// Body
$body-bg: #e4e4ee;// #f8fafc;

// Typography//// 
$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$dark-blue: #174073;
$sky-blue: #71cafe;
$gray-100: #d9d9d9;
$text-muted: #000000;
$white:#fff;
$black:#000000;

// Font Awesome
$fa-font-path: "../webfonts";
