.menu {
    .notification {
        position: absolute;
        top: 100%;
        right: -50%;
        padding: 0;
        padding-top: 16px;
        line-height: normal !important;
        background-color: $primary;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        line-height: 48px;
        width: 100%;
        min-width: 280px;
        text-transform: none;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-10px);
        max-width: 280px;
        max-height: 60vh;
        overflow: auto;
        @include transition(none);

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 16px;
            border-width: 8px 8px 0 8px;
            border-color: white transparent transparent transparent;
            border-style: solid;
        }

        li {
            display: block;
            margin: 0;

            a {
                position: relative;
                color: white;
                padding: 16px;

                i {
                    color: $sky-blue;
                }

                &:hover,
                &.router-link-exact-active,
                &.router-link-active {
                    background-color: transparent;
                    color: $sky-blue;
                }

                &:before {
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 4px;
                    background-color: $sky-blue;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }

                &:after {
                    content: "";
                    height: 1px;
                    left: 16px;
                    right: 16px;
                    bottom: 1px;
                    position: absolute;
                    background-color: rgba($white, 0.1);
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.05);
                }

                &.router-link-exact-active,
                &.router-link-active {
                    color: white;
                }
            }

            h6 {
                font-size: 12px;
                letter-spacing: -0.15px;
                line-height: 14.22px;
                font-weight: normal;
                margin-bottom: 4px;
                padding-right: 20px;

                strong {
                    display: block;
                    font-weight: bold;
                    margin-bottom: 4px;
                }
                br {
                    display: none;
                }
            }
            time {
                font-size: 12px;
                letter-spacing: -0.15px;
                line-height: 14px;
                font-weight: 600;
                color: $sky-blue;
            }

            &.read {
                opacity: 0.6;

                h6 {
                    font-weight: normal;
                }
                time {
                    font-weight: normal;
                }

                a {
                    &:before {
                        content: none;
                    }
                }
            }

            &.more {
                a {
                    text-align: center;
                    &:before {
                        content: none;
                    }
                }

                p {
                    font-size: 12px;
                    line-height: normal;
                    font-weight: 600;
                }
            }
            &.footer {
                border-bottom: none;
                text-align: right;

                a {
                    color: $sky-blue;
                    display: inline-block;
                    &:before {
                        content: none;
                    }

                    &:hover {
                        color: white;
                    }
                }
            }
        }

        &.toggled {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            @include transition(all 200ms ease-in);
        }
    }
}

.Vue-Toastification__container {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 20px;
}
.Vue-Toastification__toast {
    &--default {
        &.notification {
            &-toast {
                position: relative;
                padding-left: 56px;
                max-width: 190px;
                background-color: rgba($black, 0.8);
                margin-right: 8px;
                cursor: pointer;

                .Vue-Toastification {
                    &__icon {
                        margin: 0;
                        position: absolute;
                        top: 22px;
                        left: 22px;
                        height: auto;
                    }
                    &__close {
                        &-button {
                            &:hover,
                            &:focus {
                                outline: none;
                            }
                        }
                    }
                }

                &:hover {
                    background-color: $primary;
                }
            }
        }
    }
}
