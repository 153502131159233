


@mixin material-icon($content: '') {
    content: $content;
    font-family: 'Nunito';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: inherit;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}