.select2-results__option {
    .breadcrumbs {
        font-size: 8px;
        font-weight: 600;
        opacity: 0.6;

        span {
            &:not(:last-child) {
                &:after {
                    content: '/';
                    display: inline-block;
                    margin: 0 2px;
                    opacity: 0.4;
                }
            }
        }
    }

    &:not(:last-child) {
        border-bottom: 1px solid rgba(#D9D9D9, .4);
    }
}
.select2-container--default .select2-selection--single .select2-selection__clear {
    z-index: 10 !important;
    &:hover {
        color: $red !important;
    }
}
.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: $white;

    .select2-selection__rendered {
        color: rgba(#393939, .5) !important;
    }

    .select2-selection__arrow {
        opacity: .2 !important;
    }
}
.select2-container--default.select2-container--disabled.select2-container--focus {
    .select2-selection--single {
        outline: none !important;
    }
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 30px !important;
}
.select2-container .select2-selection--single {
    height: 38px !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 32px !important;
    width: 32px !important;
    margin-top: 5px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none !important;
    line-height: 30px !important;
    display: inline-block !important;
    height: 100% !important;
    top: 0 !important;
    right: 0 !important;
    color: $primary !important;

    @extend %fa-icon;
    @extend .fas;

    &:after {
        //@include material-icon("\f0dc");
        content : fa-content("\f0dc");
        margin-top: 2px !important;
    }
}
.select2-container--default .select2-selection--single {
    border-radius: 4px !important;
    background-color:  #D9D9D9 !important;
    border: 1px solid #9b9898 !important;

}
.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 8px !important;
    font-size: 12px !important;
}
.select2-container--default .select2-selection--multiple {
    line-height: 1;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0 10px;
    margin-bottom: 4px;
}
.select2-container .select2-search--inline .select2-search__field {
    margin-top: 8px;
}
.select2-container--default .select2-selection--multiple {
    border-color: #D9D9D9;
    height: auto;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    line-height: 20px;
    background-color: $primary;
    color: $white;
    border: none;
    font-size: 0.9em;

    &.select2-search--inline {
        background-color: transparent;
        line-height: 1;
        font-size: 1em;
    }
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    margin-right: 4px;
    color: $white;

    &:hover {
        color: $red;
    }
}
.vue-daterange-picker {
    width: 100%;
}