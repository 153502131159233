.uploader {
    &__picker {
        width: 0;
        height: 0;
        opacity: 0;
        display: none;
    }

    &__preview {
        &-image {
            position: relative;
            max-width: 200px;
            position: relative;
            margin-right: 10px;
            text-align: center;
            border-radius: 3px;
            overflow: hidden;
            background-color: $gray-100;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 100%;
                max-height: 200px;
            }

            &__remove {
                position: absolute;
                top: 5px;
                right: 5px;
                display: inline-block;
                width: 20px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                font-size: 20px;
            }
        }
    }
}